import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
import DifficultyDot from "@/game-val/DifficultyDot.jsx";
import {
  DIFFICULTY_OPTIONS,
  getGuideThumbnail,
} from "@/game-val/guides-utils.mjs";
import { Tag, Tags } from "@/game-val/GuidesLayout.jsx";
import BlitzPlay from "@/inline-assets/play-circle.svg";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import titleCase from "@/util/title-case.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Lineup = styled("a")`
  display: flex;
  padding: var(--sp-3) var(--sp-6);
  gap: var(--sp-3);
  align-items: center;
  background: var(--shade8);

  &:hover {
    background: var(--shade3-15);
  }

  &:not(:last-child) {
    box-shadow: inset 0px -1px var(--card-inset-border);
  }

  .thumb {
    position: relative;
    height: 64px;

    img {
      height: 100%;
      border-radius: var(--br);
      min-width: 64px;
    }

    svg {
      height: 38px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const AgentTag = styled(Tag)`
  padding-left: 0;
  img {
    border-top-left-radius: var(--br);
    border-bottom-left-radius: var(--br);
  }
`;

const GUIDES = [
  {
    id: "madf5i979l",
    map: "ascent",
    side: "defending",
    agent: "jett",
    title: "Aggressive Early Round Two Player Dash Setup for A Lobby",
    video: `${appURLs.CDN_VIDEOS}/valorant/guides/jett-ascent-def-amain-asite-tailwind.mp4`,
    difficulty: "pro",
  },
  {
    id: "2gmtjdppbbn",
    map: "split",
    side: "attacking",
    agent: "gekko",
    title: "Early Round Mid Aggression with Safe Dizzy",
    video: `${appURLs.CDN_VIDEOS}/valorant/guides/gekko-split-atk-mid-ramen-dizzy.mp4`,
    difficulty: "medium",
  },
  {
    id: "z0ort6swfva",
    map: "split",
    side: "defending",
    agent: "sage",
    title: "Mid Top Delay / Anti-Box Jump Wall",
    video: `${appURLs.CDN_VIDEOS}/valorant/guides/sage-split-def-midtop-midmail-barrierorb.mp4`,
    difficulty: "easy",
  },
];

export default function HomeLineupsTile() {
  const { t } = useTranslation();
  const {
    val: { meta: { agents: { list: agents } = {} } = {} },
  } = useSnapshot(readState);

  return (
    <GameGuideTileLg
      title={["common:navigation.lineups", "Lineups"]}
      description={[
        "home:guides.val.lineups.description",
        "Unlock your Valorant potential with our lineups guide. Understand optimal agent setups, execute perfect plays, and climb the rank ladder!",
      ]}
      buttonText={["common:navigation.viewLineups", "View Lineups"]}
      href={"/valorant/lineups"}
    >
      {GUIDES.map((g) => {
        const { id, side, map, agent, video, title, difficulty } = g;
        const agentObj = agents?.find((a) => a.key === agent);
        const thumb = getGuideThumbnail(video, map, agent);
        const difficultyObj = DIFFICULTY_OPTIONS.find(
          (d) => d.value === difficulty,
        );
        return (
          <Lineup
            key={id}
            href={`/valorant/lineups/${map}?side=${side}&agent=${agent}&guide=${id}`}
          >
            <div className="thumb">
              <img src={thumb} loading="lazy" />
              <BlitzPlay className="play-icon" />
            </div>
            <div>
              <p className="type-subtitle--bold">{title}</p>
              <Tags>
                {agentObj && (
                  <AgentTag>
                    <img src={agentObj.images.headshot} loading="lazy" />
                    <span className="type-caption--bold">
                      {t(`val:agents.${agentObj.key}`, agentObj.name)}
                    </span>
                  </AgentTag>
                )}
                <Tag>
                  <span className="type-caption--bold">
                    {t(`val:maps.${map}`, titleCase(map))}
                  </span>
                </Tag>
                <Tag>
                  <DifficultyDot difficulty={difficulty} />
                  <span className="type-caption--bold">
                    {difficultyObj && t(...difficultyObj.text)}
                  </span>
                </Tag>
              </Tags>
            </div>
          </Lineup>
        );
      })}
    </GameGuideTileLg>
  );
}
